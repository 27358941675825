import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`이 교재는 프로그래밍 경험이 전무한 독자를 대상으로, 최대한 빠른 시간 내에 실무에서 사용되는 JavaScript 언어의 문법과 기능들을 습득할 수 있도록 제작했습니다.`}</p>
    <h2>{`이 교재의 특징`}</h2>
    <ul>
      <li parentName="ul">{`책을 여러 파트로 나누어서, 학습 단계별로 교재의 적절한 파트를 골라서 활용할 수 있도록 구성했습니다.`}</li>
      <li parentName="ul">{`최신 JavaScript의 문법 및 기능을 다루었습니다.`}</li>
    </ul>
    <h2>{`기여자`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/seungha-kim"
        }}>{`김승하`}</a>{` `}{
          /* 최초 저자 */
        }</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/chayeoi"
        }}>{`김찬연`}</a>{` `}{
          /* 오타 수정 */
        }</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/stared329"
        }}>{`김재명`}</a>{` `}{
          /* 변수 선언 방법 비교표 제작 */
        }</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/underbleu"
        }}>{`김보영`}</a>{` `}{
          /* 오타 수정 */
        }</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/BeomyeonAndrewKim"
        }}>{`김범연`}</a>{` `}{
          /* 내용 오류 수정 */
        }</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jiwonkirn"
        }}>{`김지원`}</a>{` `}{
          /* 내용 오류 수정 */
        }</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sehyunchung"
        }}>{`정세현`}</a>{` `}{
          /* 스프린트 참여 */
        }</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/changseon-shin"
        }}>{`신창선`}</a>{` `}{
          /* 스프린트 참여 */
        }</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/leekeunhwan"
        }}>{`이근환`}</a>{` `}{
          /* 스프린트 참여 */
        }</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/KimSejune"
        }}>{`김세준`}</a>{` `}{
          /* 스프린트 참여 */
        }</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/hellomac87"
        }}>{`이강산`}</a>{` `}{
          /* 스프린트 참여 */
        }</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/yoeubi"
        }}>{`성중원`}</a>{` `}{
          /* 스프린트 참여 */
        }</li>
    </ul>
    <hr></hr>
    <a rel="license" href="http://creativecommons.org/licenses/by-sa/4.0/"><img alt="크리에이티브 커먼즈 라이선스" style={{
        "borderWidth": "0"
      }} src="https://i.creativecommons.org/l/by-sa/4.0/88x31.png" /></a><br />이 저작물은 <a rel="license" href="http://creativecommons.org/licenses/by-sa/4.0/">크리에이티브 커먼즈 저작자표시-동일조건변경허락 4.0 국제 라이선스</a>에 따라 이용할 수 있습니다.

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      